@import '../../../styles/colors';

.selftour-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .title-section {
    display: flex;
    margin: 30px 20px 40px 20px;
    justify-content: center;
    align-items: center;
    text-align: center;

    .close-btn {
      .close {
        width: 22px;
        height: 22px;
      }
    }
    .isclosed-title {
      text-align: center;
      font-size: 16px;
      font-weight: 700;
    }
    .title {
      flex: 1;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .subtitle-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px 20px;
    .subtitle {
      font-size: 24px;
      font-weight: 700;
    }
  }
  .tourInput-section {
    margin: 35px 20px 0px 20px;
  }
  .btn-section {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    gap: 8px;
    margin: 35px 20px 0px 20px;
  }
  .tour-schedule-section {
    flex: 1;
    margin-top: 91px;
    background-color: $grey1;

    .text {
      margin-top: 44px;
      text-align: center;
      color: $grey4;
      white-space: pre-wrap;
    }
  }
}

.floating-container {
  display: flex;
  align-items: center;
  gap: 8px;

  .button-container {
    padding: 20px;
  }
}
