@charset "UTF-8";
.h1,
.headline1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
}

.h2,
.headline2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

.text-xxl,
.subtitle1 {
  font-weight: 700;
  font-size: 16px;
}

.text-xl,
.subtitle2 {
  font-weight: 400;
  font-size: 16px;
}

.text-l,
.body1 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.text-m,
.body2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.text-s,
.caption1 {
  font-weight: 700;
  font-size: 12px;
}

.text-xs,
.caption2 {
  font-weight: 400;
  font-size: 12px;
}

.text-button,
.button {
  font-weight: 700;
  font-size: 15px;
}

.text-input,
.input {
  font-weight: 400;
  font-size: 15px;
}

.primary-lighten {
  color: #f9fbf2;
}

.primary {
  color: #bbe309;
}

.primary-dark {
  color: #94b20a;
}

.secondary-lighten {
  color: #f1e1f4;
}

.secondary {
  color: #b867c6;
}

.secondary-dark {
  color: #863895;
}

.white {
  color: #ffffff;
}

.grey1 {
  color: #eeeeee;
}

.grey3 {
  color: #d9d9d9;
}

.grey4 {
  color: #999999;
}

.grey5 {
  color: #767676;
}

.black {
  color: #111111;
}

.red,
.error {
  color: #dc0000;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Pretendard", sans-serif;
  list-style: none;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: -2%;
  color: #111111;
  -webkit-text-size-adjust: 100%;
  max-width: 500px;
}
*::-webkit-scrollbar {
  width: 0;
  height: 0;
}

button {
  background-color: transparent;
  cursor: pointer;
  border: 0;
  outline: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #111111;
}

div {
  cursor: default;
}

html,
body {
  position: relative;
  overscroll-behavior: contain;
  margin: auto;
  background-color: #eeeeee;
}

#root {
  min-height: 100vh;
  overflow-x: hidden;
  background-color: #ffffff;
}

.swiper-pagination-bullet {
  background: #999999;
}

.swiper-pagination-bullet-active {
  background: #bbe309;
}

.divider {
  width: 100%;
  height: 8px;
  background-color: #fafafa;
}

@media (min-width: 768px) {
  /* 세로 스크롤바 */
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  /* 스크롤바 색상 */
  ::-webkit-scrollbar-thumb {
    background: #eeeeee; /* 원래 색상으로 복원 */
    border-radius: 30px; /* 원래 테두리 스타일로 복원 */
  }
  /* 스크롤바 트랙 색상 */
  ::-webkit-scrollbar-track {
    background: initial; /* 원래 색상으로 복원 */
    padding: 10px;
  }
}/*# sourceMappingURL=core.css.map */