@import '../../../../styles/colors';

.modal-root {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%);
  background-color: #00000099;
  opacity: 1;
  display: flex;
  align-items: flex-end;
  z-index: 99999999;

  .modal-main {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $white;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    transition: all 300ms ease-in-out;

    &.invisible {
      transform: translateY(80%);
    }
  }

  .modal-header-root {
    .handle-bar-container {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white;

      .bar {
        width: 100px;
        height: 4px;
        border-radius: 4px;
      }
    }

    .header-container {
      position: relative;
      display: flex;
      width: 100%;
      height: 56px;
      align-items: center;
      justify-content: center;

      .close-btn {
        position: absolute;
        right: 20px;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .modal-content-root {
    padding-top: 20px;
    background-color: $white;
    min-height: fit-content;
    max-height: 60vh;
    overflow-y: auto;
  }

  .modal-footer-root {
    width: 100%;
    display: flex;
    gap: 8px;
    background-color: $white;
    padding: 7px 20px;
  }
}
