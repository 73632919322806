@import '../../../../styles/colors';

reach-portal {
  .dialog-root {
    background-color: $white;
    position: relative;
    max-width: 500px;
    width: 100%;
    left: 50%;

    [data-rsbs-backdrop] {
      position: fixed;
      width: 100%;
      height: 100vh;
      z-index: 9;
      transform: translateX(-50%), translateY(-100%);
    }

    [data-rsbs-overlay] {
      padding: 0;
      border-radius: 20px 20px 0 0;
      height: fit-content;
      max-height: 70vh;
      width: 100%;
      max-width: 500px;
      z-index: 12;
    }

    [data-rsbs-header] {
      border-radius: 20px 20px 0 0;
      padding: 0;
      box-shadow: none;

      &::before {
        display: none;
      }

      .handle-bar-container {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        .bar {
          width: 100px;
          height: 4px;
          background-color: $grey2;
          border-radius: 4px;
        }
      }

      .header-container {
        position: relative;
        display: flex;
        width: 100%;
        height: 56px;
        align-items: center;
        justify-content: center;

        .close-btn {
          position: absolute;
          right: 20px;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    [data-rsbs-footer] {
      padding: 7px 20px;
      box-shadow: none;

      .footer-container {
        width: 100%;
        display: flex;
        gap: 8px;
      }
    }

    [data-rsbs-scroll] {
      flex-shrink: 1;
      overflow: auto;

      -webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      [data-rsbs-content] {
        overflow: hidden;
      }
    }

    .content-root {
      padding-top: 20px;
      margin-bottom: 56px;
      background-color: $white;
      min-height: 50px;
      max-height: 100vh;
    }
  }
}
