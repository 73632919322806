.btn {
  height: 38px;
  padding: 10px 12px;
  border-radius: 20px;
  background-color: #fafafa;
  border: 1px solid #eeeeee;
  color: #767676;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.btn.click {
  background-color: #bbe309;
  border: 1px solid #eeeeee;
  color: #111111;
}
.btn.medium {
  background-color: #f9fbf2;
  border: 1px solid #f9fbf2;
  color: #94b20a;
}
.btn.medium.click {
  background-color: #f1e1f4;
  border: 1px solid #f1e1f4;
  color: #863895;
}/*# sourceMappingURL=chip.module.css.map */