.modal-root {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
  display: flex;
  align-items: flex-end;
  z-index: 99999999;
}
.modal-root .modal-main {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  transition: all 300ms ease-in-out;
}
.modal-root .modal-main.invisible {
  transform: translateY(80%);
}
.modal-root .modal-header-root .handle-bar-container {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
.modal-root .modal-header-root .handle-bar-container .bar {
  width: 100px;
  height: 4px;
  border-radius: 4px;
}
.modal-root .modal-header-root .header-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  justify-content: center;
}
.modal-root .modal-header-root .header-container .close-btn {
  position: absolute;
  right: 20px;
}
.modal-root .modal-header-root .header-container .close-btn img {
  width: 24px;
  height: 24px;
}
.modal-root .modal-content-root {
  padding-top: 20px;
  background-color: #ffffff;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 60vh;
  overflow-y: auto;
}
.modal-root .modal-footer-root {
  width: 100%;
  display: flex;
  gap: 8px;
  background-color: #ffffff;
  padding: 7px 20px;
}/*# sourceMappingURL=modal.styles.css.map */