reach-portal .dialog-root {
  background-color: #ffffff;
  position: relative;
  max-width: 500px;
  width: 100%;
  left: 50%;
}
reach-portal .dialog-root [data-rsbs-backdrop] {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9;
  transform: translateX(-50%), translateY(-100%);
}
reach-portal .dialog-root [data-rsbs-overlay] {
  padding: 0;
  border-radius: 20px 20px 0 0;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 70vh;
  width: 100%;
  max-width: 500px;
  z-index: 12;
}
reach-portal .dialog-root [data-rsbs-header] {
  border-radius: 20px 20px 0 0;
  padding: 0;
  box-shadow: none;
}
reach-portal .dialog-root [data-rsbs-header]::before {
  display: none;
}
reach-portal .dialog-root [data-rsbs-header] .handle-bar-container {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
reach-portal .dialog-root [data-rsbs-header] .handle-bar-container .bar {
  width: 100px;
  height: 4px;
  background-color: #eeeeee;
  border-radius: 4px;
}
reach-portal .dialog-root [data-rsbs-header] .header-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  justify-content: center;
}
reach-portal .dialog-root [data-rsbs-header] .header-container .close-btn {
  position: absolute;
  right: 20px;
}
reach-portal .dialog-root [data-rsbs-header] .header-container .close-btn img {
  width: 24px;
  height: 24px;
}
reach-portal .dialog-root [data-rsbs-footer] {
  padding: 7px 20px;
  box-shadow: none;
}
reach-portal .dialog-root [data-rsbs-footer] .footer-container {
  width: 100%;
  display: flex;
  gap: 8px;
}
reach-portal .dialog-root [data-rsbs-scroll] {
  flex-shrink: 1;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
reach-portal .dialog-root [data-rsbs-scroll] -webkit-scrollbar {
  display: none;
}
reach-portal .dialog-root [data-rsbs-scroll] [data-rsbs-content] {
  overflow: hidden;
}
reach-portal .dialog-root .content-root {
  padding-top: 20px;
  margin-bottom: 56px;
  background-color: #ffffff;
  min-height: 50px;
  max-height: 100vh;
}/*# sourceMappingURL=dialog.styles.css.map */