.h1,
.headline1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
}

.h2,
.headline2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

.text-xxl,
.subtitle1 {
  font-weight: 700;
  font-size: 16px;
}

.text-xl,
.subtitle2 {
  font-weight: 400;
  font-size: 16px;
}

.text-l,
.body1 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.text-m,
.body2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.text-s,
.caption1 {
  font-weight: 700;
  font-size: 12px;
}

.text-xs,
.caption2 {
  font-weight: 400;
  font-size: 12px;
}

.text-button,
.button {
  font-weight: 700;
  font-size: 15px;
}

.text-input,
.input {
  font-weight: 400;
  font-size: 15px;
}

.primary-lighten {
  color: $primary-lighten;
}

.primary {
  color: $primary;
}

.primary-dark {
  color: $primary-dark;
}

.secondary-lighten {
  color: $secondary-lighten;
}

.secondary {
  color: $secondary;
}

.secondary-dark {
  color: $secondary-dark;
}

.white {
  color: $white;
}

.grey1 {
  color: $grey2;
}

.grey3 {
  color: $grey3;
}

.grey4 {
  color: $grey4;
}

.grey5 {
  color: $grey5;
}

.black {
  color: $black;
}

.red,
.error {
  color: $error;
}

// mixin

@mixin h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
}

@mixin h2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

@mixin text-xxl {
  font-weight: 700;
  font-size: 16px;
}

@mixin text-xl {
  font-weight: 400;
  font-size: 16px;
}

@mixin text-l {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

@mixin text-m {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@mixin text-s {
  font-weight: 700;
  font-size: 12px;
}

@mixin text-xs {
  font-weight: 400;
  font-size: 12px;
}

@mixin text-button {
  font-weight: 700;
  font-size: 15px;
}

@mixin text-input {
  font-weight: 400;
  font-size: 15px;
}

@mixin primary-lighten {
  color: $primary-lighten;
}

@mixin primary {
  color: $primary;
}

@mixin primary-dark {
  color: $primary-dark;
}

@mixin secondary-lighten {
  color: $secondary-lighten;
}

@mixin secondary {
  color: $secondary;
}

@mixin secondary-dark {
  color: $secondary-dark;
}

@mixin white {
  color: $white;
}

@mixin grey1 {
  color: $grey2;
}

@mixin grey3 {
  color: $grey3;
}

@mixin grey4 {
  color: $grey4;
}

@mixin grey5 {
  color: $grey5;
}

@mixin black {
  color: $black;
}

@mixin red {
  color: $error;
}

@mixin error {
  color: $error;
}
