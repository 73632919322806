@import '../../../../styles/colors';

.btn {
  height: 38px;
  padding: 10px 12px;
  border-radius: 20px;
  background-color: $grey1;
  border: 1px solid $grey2;
  color: $grey5;
  min-width: fit-content;

  &.click {
    background-color: $primary;
    border: 1px solid $grey2;
    color: #111111;
  }
  &.medium {
    background-color: $primary-lighten;
    border: 1px solid $primary-lighten;
    color: $primary-dark;
    &.click {
      background-color: $secondary-lighten;
      border: 1px solid $secondary-lighten;
      color: $secondary-dark;
    }
  }
}
