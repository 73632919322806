.login-root {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 140px 20px;
}
.login-root .login-background-section {
  position: absolute;
  left: 0;
  top: 0;
  height: 285px;
  width: 100%;
  background: linear-gradient(180.54deg, rgba(187, 227, 9, 0.24) 0.71%, rgba(187, 227, 9, 0) 99.79%);
  z-index: 0;
}
.login-root .logo-section {
  display: flex;
  margin-bottom: 78px;
}
.login-root .title-section {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.login-root .title-section .title {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}
.login-root .title-section .sub-title {
  margin-bottom: 54px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #767676;
}
.login-root .login-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  z-index: 9;
}/*# sourceMappingURL=login.css.map */