// primary
$primary-lighten: #f9fbf2;
$primary: #bbe309;
$primary-dark: #94b20a;
$primary-bg: linear-gradient(
  180.54deg,
  rgba(187, 227, 9, 0.24) 0.71%,
  rgba(187, 227, 9, 0) 99.79%
);

// secondary
$secondary-lighten: #f1e1f4;
$secondary: #b867c6;
$secondary-dark: #863895;

// grey scale
$white: #ffffff;
$grey1: #fafafa;
$grey2: #eeeeee;
$grey3: #d9d9d9;
$grey4: #999999;
$grey5: #767676;
$black: #111111;

// system
$error: #dc0000;
$success: #42c803;
