@import '../../../styles/mixin';

.select-region-root {
  // overflow-y: scroll;

  .text-title {
    margin: 0px 20px 0px 20px;
  }
  .region-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto);
    column-gap: 12px;
    row-gap: 20px;
    margin: 16px 20px 40px 20px;

    .region-card-body {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .region {
        position: relative;
        flex-shrink: 0;
        aspect-ratio: 1 / 1;
        max-width: 104px;
        max-height: 104px;
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        border-radius: 50%;
        background: #111111;
        border: 2px solid $white;

        &.select {
          border-color: $primary;
        }

        .region-img {
          width: 100%;
          height: 100%;
          opacity: 0.8;
          border-radius: 50%;
        }
        .text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
        }
      }
    }
  }
}
