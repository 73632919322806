.selftour-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.selftour-root .title-section {
  display: flex;
  margin: 30px 20px 40px 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.selftour-root .title-section .close-btn .close {
  width: 22px;
  height: 22px;
}
.selftour-root .title-section .isclosed-title {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}
.selftour-root .title-section .title {
  flex: 1;
  font-size: 18px;
  font-weight: 700;
}
.selftour-root .subtitle-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 20px;
}
.selftour-root .subtitle-section .subtitle {
  font-size: 24px;
  font-weight: 700;
}
.selftour-root .tourInput-section {
  margin: 35px 20px 0px 20px;
}
.selftour-root .btn-section {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  gap: 8px;
  margin: 35px 20px 0px 20px;
}
.selftour-root .tour-schedule-section {
  flex: 1;
  margin-top: 91px;
  background-color: #fafafa;
}
.selftour-root .tour-schedule-section .text {
  margin-top: 44px;
  text-align: center;
  color: #999999;
  white-space: pre-wrap;
}

.floating-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.floating-container .button-container {
  padding: 20px;
}/*# sourceMappingURL=selftour.css.map */