@import '../../../styles/colors';

.is-tour-open-root {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  .open-section {
    display: flex;
    flex-direction: column;

    .open-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      border-bottom: 1px solid $grey3;
      .text {
        color: $grey5;

        &.check {
          color: black;
        }
      }
      .public-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        width: 24px;
        height: 24px;
        border: 1px solid $grey3;

        &.check {
          background-color: $primary;
          border-color: $primary;
        }

        .inner-btn {
          border-radius: 20px;
          width: 12px;
          height: 12px;
          background-color: $grey3;

          &.check {
            background-color: white;
          }
        }
      }
    }
  }
}
