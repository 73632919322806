@import '../../../../styles/colors';

.list-item-root {
  width: 100%;
  display: flex;
  height: 48px;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid $grey2;

  .label {
    flex: 1;
    color: $grey5;
  }

  &.checked {
    .label {
      color: $black;
    }
  }

  .checkbox {
    width: 24px;
    height: 24px;
  }
}
