.is-tour-open-root {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
}
.is-tour-open-root .open-section {
  display: flex;
  flex-direction: column;
}
.is-tour-open-root .open-section .open-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid #d9d9d9;
}
.is-tour-open-root .open-section .open-list .text {
  color: #767676;
}
.is-tour-open-root .open-section .open-list .text.check {
  color: black;
}
.is-tour-open-root .open-section .open-list .public-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 24px;
  height: 24px;
  border: 1px solid #d9d9d9;
}
.is-tour-open-root .open-section .open-list .public-btn.check {
  background-color: #bbe309;
  border-color: #bbe309;
}
.is-tour-open-root .open-section .open-list .public-btn .inner-btn {
  border-radius: 20px;
  width: 12px;
  height: 12px;
  background-color: #d9d9d9;
}
.is-tour-open-root .open-section .open-list .public-btn .inner-btn.check {
  background-color: white;
}/*# sourceMappingURL=istouropen.module.css.map */