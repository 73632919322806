.btn {
  width: 100%;
  height: 56px;
  border-radius: 6px;
}
.btn.fill.primary {
  background-color: #bbe309;
  color: black;
}
.btn.fill.primary-lighten {
  background-color: #f9fbf2;
  color: #bbe309;
}
.btn.fill.primary-dark {
  background-color: #94b20a;
  color: #bbe309;
}
.btn.fill.secondary {
  background-color: #b867c6;
  color: white;
}
.btn.fill.secondary-lighten {
  background-color: #f1e1f4;
  color: #b867c6;
}
.btn.fill.grey3 {
  background-color: #d9d9d9;
  color: #999999;
}
.btn.fill.grey1 {
  background-color: #fafafa;
  color: #767676;
  border: 1px solid #eeeeee;
}
.btn.fill.disabled {
  background-color: #d9d9d9;
  color: #999999;
}
.btn.outline.primary {
  background-color: #f9fbf2;
  color: #94b20a;
  border: 1px solid #bbe309;
}
.btn.outline.primary-lighten {
  background-color: #ffffff;
  color: #94b20a;
  border: 1px solid #bbe309;
}
.btn.outline.primary-dark {
  background-color: #ffffff;
  color: #94b20a;
  border: 1px solid #94b20a;
}
.btn.outline.secondary {
  background-color: #f1e1f4;
  color: #b867c6;
  border: 1px solid #b867c6;
}
.btn.outline.secondary-lighten {
  background-color: #ffffff;
  color: #b867c6;
  border: 1px solid #b867c6;
}
.btn.outline.grey3 {
  background-color: #ffffff;
  color: #999999;
  border: 1px solid #d9d9d9;
}
.btn.outline.disabled {
  background-color: #ffffff;
  border-color: #d9d9d9;
  color: #999999;
}
.btn.medium {
  height: 40px;
}/*# sourceMappingURL=button.module.css.map */