.list-item-root {
  width: 100%;
  display: flex;
  height: 48px;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #eeeeee;
}
.list-item-root .label {
  flex: 1;
  color: #767676;
}
.list-item-root.checked .label {
  color: #111111;
}
.list-item-root .checkbox {
  width: 24px;
  height: 24px;
}/*# sourceMappingURL=ccheckbox.list.item.module.css.map */