.h1,
.headline1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
}

.h2,
.headline2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

.text-xxl,
.subtitle1 {
  font-weight: 700;
  font-size: 16px;
}

.text-xl,
.subtitle2 {
  font-weight: 400;
  font-size: 16px;
}

.text-l,
.body1 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.text-m,
.body2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.text-s,
.caption1 {
  font-weight: 700;
  font-size: 12px;
}

.text-xs,
.caption2 {
  font-weight: 400;
  font-size: 12px;
}

.text-button,
.button {
  font-weight: 700;
  font-size: 15px;
}

.text-input,
.input {
  font-weight: 400;
  font-size: 15px;
}

.primary-lighten {
  color: #f9fbf2;
}

.primary {
  color: #bbe309;
}

.primary-dark {
  color: #94b20a;
}

.secondary-lighten {
  color: #f1e1f4;
}

.secondary {
  color: #b867c6;
}

.secondary-dark {
  color: #863895;
}

.white {
  color: #ffffff;
}

.grey1 {
  color: #eeeeee;
}

.grey3 {
  color: #d9d9d9;
}

.grey4 {
  color: #999999;
}

.grey5 {
  color: #767676;
}

.black {
  color: #111111;
}

.red,
.error {
  color: #dc0000;
}

.select-region-root .text-title {
  margin: 0px 20px 0px 20px;
}
.select-region-root .region-card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto);
  -moz-column-gap: 12px;
       column-gap: 12px;
  row-gap: 20px;
  margin: 16px 20px 40px 20px;
}
.select-region-root .region-card-container .region-card-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-region-root .region-card-container .region-card-body .region {
  position: relative;
  flex-shrink: 0;
  aspect-ratio: 1/1;
  max-width: 104px;
  max-height: 104px;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 50%;
  background: #111111;
  border: 2px solid #ffffff;
}
.select-region-root .region-card-container .region-card-body .region.select {
  border-color: #bbe309;
}
.select-region-root .region-card-container .region-card-body .region .region-img {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  border-radius: 50%;
}
.select-region-root .region-card-container .region-card-body .region .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}/*# sourceMappingURL=selectregion.module.css.map */