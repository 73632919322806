@import './mixin';

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'Pretendard', sans-serif;
  list-style: none;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: -2%;
  color: $black;
  -webkit-text-size-adjust: 100%;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  max-width: 500px;
}

button {
  background-color: transparent;
  cursor: pointer;
  border: 0;
  outline: 0;
}

input {
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: $black;
}

div {
  cursor: default;
}

html,
body {
  position: relative;
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  overscroll-behavior: contain;
  // -webkit-tap-highlight-color: rgba(11, 11, 11, 0);

  margin: auto;
  background-color: $grey2;
}

#root {
  min-height: 100vh;
  overflow-x: hidden;
  background-color: $white;
}

.swiper-pagination-bullet {
  background: $grey4;
}

.swiper-pagination-bullet-active {
  background: $primary;
}

.divider {
  width: 100%;
  height: 8px;
  background-color: $grey1;
}

@media (min-width: 768px) {
  /* 세로 스크롤바 */
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  /* 스크롤바 색상 */
  ::-webkit-scrollbar-thumb {
    background: $grey2; /* 원래 색상으로 복원 */
    border-radius: 30px; /* 원래 테두리 스타일로 복원 */
  }

  /* 스크롤바 트랙 색상 */
  ::-webkit-scrollbar-track {
    background: initial; /* 원래 색상으로 복원 */
    padding: 10px;
  }
}
