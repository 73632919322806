@import '../../../../styles/colors';

.btn {
  width: 100%;
  height: 56px;
  border-radius: 6px;

  &.fill {
    &.primary {
      background-color: $primary;
      color: black;
    }
    &.primary-lighten {
      background-color: $primary-lighten;
      color: $primary;
    }
    &.primary-dark {
      background-color: $primary-dark;
      color: $primary;
    }
    &.secondary {
      background-color: $secondary;
      color: white;
    }
    &.secondary-lighten {
      background-color: $secondary-lighten;
      color: $secondary;
    }
    &.grey3 {
      background-color: $grey3;
      color: $grey4;
    }
    &.grey1 {
      background-color: $grey1;
      color: $grey5;
      border: 1px solid $grey2;
    }

    &.disabled {
      background-color: $grey3;
      color: $grey4;
    }
  }
  &.outline {
    &.primary {
      background-color: $primary-lighten;
      color: $primary-dark;
      border: 1px solid $primary;
    }
    &.primary-lighten {
      background-color: $white;
      color: $primary-dark;
      border: 1px solid $primary;
    }
    &.primary-dark {
      background-color: $white;
      color: $primary-dark;
      border: 1px solid $primary-dark;
    }
    &.secondary {
      background-color: $secondary-lighten;
      color: $secondary;
      border: 1px solid $secondary;
    }
    &.secondary-lighten {
      background-color: $white;
      color: $secondary;
      border: 1px solid $secondary;
    }
    &.grey3 {
      background-color: $white;
      color: $grey4;
      border: 1px solid $grey3;
    }

    &.disabled {
      background-color: $white;
      border-color: $grey3;
      color: $grey4;
    }
  }
  &.medium {
    height: 40px;
  }
}
